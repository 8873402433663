.network-error {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 24px;
  isolation: isolate;
  min-width: 400px;
  min-height: 349px;
  background: #4d0cb9;
  border-radius: 10px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 10px 10px -5px rgba(136, 136, 136, 0.25);
}

.i-close {
  position: absolute;
  top: 21px;
  right: 21px;
  cursor: pointer;
}

.title-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content-title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin-top: 32px;
  color: rgb(13, 25, 53);
}

.content-text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 32px;
  color: rgb(13, 25, 53);
}

.switch-to {
  cursor: pointer;
  background: rgb(13, 25, 53);
  color: #ffffff;
  margin-top: 32px;
  padding: 5px 20px;
  border-radius: 20px;
}
